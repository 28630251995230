.TrainingCertifications-border {
    border: 1px solid var(--black-color);
    border-radius: 10px;
    position: relative;
}

.add-title {
    position: absolute;
    top: -15px;
    background: #f9fafb;

}

.add-title span {
    background: var(--orange-color);
    padding: 4px 10px;
    font-weight: 500;
    border-radius: 5px;
}

.Upload-file {
    display: none;
}

.inputs {
    background-color: var(--green-color);
    border: 1px solid #d1d1d1;
    border-radius: 6px;
    padding: 5px 10px;
    cursor: pointer;
    margin-bottom: 1rem;
}