.cso-feedbackformlist-border {
    border: 1px solid var(--black-color);
    border-radius: 10px;
    position: relative;
}

.list-title {
    position: absolute;
    top: -15px;
    background: #f9fafb;

}

.list-title span {
    background: var(--orange-color);
    padding: 4px 10px;
    font-weight: 500;
    border-radius: 5px;
}