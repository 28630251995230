.skillset-border {
    border: 1px solid var(--black-color);
    border-radius: 10px;
}

.profile-pic {
    height: 250px;
    width: 250px;
    border-radius: 10px;
}

.profile-pic img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.profile-pic svg {
    color: white;
}

.profile-edit-btn {
    background-color: var(--green-color);
    width: 40px;
    display: flex;
    border-radius: 0px 10px 0px 0px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    height: 40px;
    right: 0;
}

.Upload-file {
    display: none;
}

.inputs {
    border: 1px solid #d1d1d1;
    border-radius: 6px;
    padding: 5px 10px;
    cursor: pointer;
    margin-bottom: 1rem;
}

.edit-btn-skill {
    background-color: var(--green-color);
    width: 40px !important;
    display: flex;
    height: 40px !important;
    border: none !important;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}

.edit-btn-skill:disabled {
    background-color: #879c5ca3;
}

.edit-btn-skill svg {
    color: white;
}

.inputs label,
.inputs span {
    font-family: Public Sans, sans-serif;
    font-size: 16px;
}

.sub-title {
    font-size: 18px;
    font-weight: bold;
}