.add-border-outline {
    border: 1px solid var(--black-color);
    border-radius: 10px;
    position: relative;
}

.add-title {
    position: absolute;
    top: -15px;
    background: #f9fafb;
}

.add-title span {
    background: var(--orange-color);
    padding: 4px 10px;
    font-weight: 500;
    border-radius: 5px;
}

.verify-button {
    background-color: #89b72b;
    width: 40px;
    border-radius: 0px 5px 5px 0px;
    height: 38px;
    position: absolute;
    top: 17px;
    right: 9px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--black-color);
}

.verify-button svg {
    width: 20px;
    height: 20px;
}

.delete-button {
    height: 10px;
    width: 10px;
    color: red;
    cursor: pointer;
    margin: 0px 3px;
}

.list-language li {
    text-transform: capitalize;
    list-style-type: none;
}