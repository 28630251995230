.add-Client-Form-border {
    border: 1px solid var(--black-color);
    border-radius: 10px;
    position: relative;
}

.add-title {
    position: absolute;
    top: -15px;
    background: #f9fafb;
}

.add-title span {
    background: var(--orange-color);
    padding: 4px 10px;
    font-weight: 500;
    border-radius: 5px;
}

.year-title {
    color: var(--orange-color);
    font-weight: 500;
    text-decoration: underline;
}

#standardsSchemeType {
    padding: 8px !important;
}